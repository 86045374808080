import React from 'react';
import HomePageHero from '../components/HomePageHero';
import { graphql } from 'gatsby';
import { cities } from '../helpers';

import Review from '../components/Review';
import ContactUs from '../components/ContactUs';
import WhatSetsUsApart from '../components/templates/WhatSetsUsApart';
import FaqSection from '../components/FaqSection';
import SEO from '../components/SEO';
import StyledContainer from '../components/organisms/StyledContainer.js';
import CategorySection from '../components/templates/CategorySection.js';
import LocationsMapSection from '../components/LocationsMapSection';

import Flowers from "../assets/images/category_images/flowers.png";
import Prerolls from "../assets/images/category_images/prerolls.png";
import Edibles from "../assets/images/category_images/edibles.png";
import Vapes from "../assets/images/category_images/vapes.png";
import Oils from "../assets/images/category_images/oils.png";
import Beverages from "../assets/images/category_images/beverages.png";


import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from 'gatsby-background-image'

const categoriesDataA = [
    {
        src: Flowers,
        title: "Flowers",
        slug: "/livemenu?dtche[category]=flower",
    },
    {
        src: Prerolls,
        title: "Prerolls",
        slug: "/livemenu?dtche[category]=pre-rolls",
    },
    {
        src: Edibles,
        title: "Edibles",
        slug: "/livemenu?dtche[category]=edibles",
    },
    {
        src: Vapes,
        title: "Vapes",
        slug: "/livemenu?dtche[category]=vaporizers",
    },
    {
        src: Oils,
        title: "Oils",
        slug: "/livemenu?dtche[category]=concentrates",
    },
    {
        src: Beverages,
        title: "Beverages",
        slug: "/livemenu?dtche[subcategories]=drinks&dtche[category]=edibles",
    },
]

export default function Guelph({data}){
    const imageprod = getImage(data.productbg);
    const bgImageProd = convertToBgImage(imageprod);
    
    const schema = {
        "@context": "https://schema.org",
        "@type": "Dispensary",
        "name": "Ronin Cannabis",
        "image": "/logo.jpg",
        "@id": "",
        "url": "https://ronincannabis.com/",
        "telephone": "(519)-714-0420",
        "address": {
            "@type": "PostalAddress",
            "streetAddress": "86 Gordon St ",
            "addressLocality": "Guelph",
            "addressRegion": "ON",
            "postalCode": "N1H 4H6",
            "addressCountry": "CA"
        },
        "geo": {
            "@type": "GeoCoordinates",
            "latitude": 43.54124483001584, 
            "longitude": -80.24363115353586
        },
        "openingHoursSpecification": [
            {
              "@type": "OpeningHoursSpecification",
              "closes":  "20:00:00",
              "dayOfWeek": "https://schema.org/Sunday",
              "opens":  "11:00:00"
            },
            {
              "@type": "OpeningHoursSpecification",
              "closes": "22:00:00" ,
              "dayOfWeek": "https://schema.org/Saturday",
              "opens": "10:00:00"
            },
            {
              "@type": "OpeningHoursSpecification",
              "closes":  "22:00:00",
              "dayOfWeek": "https://schema.org/Thursday",
              "opens": "10:00:00"
            },
            {
              "@type": "OpeningHoursSpecification",
              "closes": "22:00:00",
              "dayOfWeek": "https://schema.org/Tuesday",
              "opens": "10:00:00"
            },
            {
              "@type": "OpeningHoursSpecification",
              "closes": "22:00:00",
              "dayOfWeek":  "https://schema.org/Friday",
              "opens": "10:00:00"
            },
            {
              "@type": "OpeningHoursSpecification",
              "closes": "22:00:00",
              "dayOfWeek": "https://schema.org/Monday",
              "opens": "10:00:00"
            },
            {
              "@type": "OpeningHoursSpecification",
              "closes": "22:00:00",
              "dayOfWeek":  "https://schema.org/Wednesday",
              "opens": "10:00:00"
            }
          ]
    }

    return(
        <>
        <SEO
            description="With so many options, how can you know where to buy the best 
            weed in Guelph? Ronin Cannabis is here to help answer that 
            question and provide amazing quality products to service all of 
            your cannabis needs, no matter what you might be looking for"
            title="Guelph Retail Store"
            schemaMarkup={schema}
        />
            <HomePageHero 
                title={"Cannabis Stores in Guelph"}
                subtitleB={"Are You Looking for Top-Quality Cannabis Products in Guelph?"}
                content={[`Tired of wandering from retail store to retail store, never sure whether 
                        you're going to like what they have to offer? Well, look no further! Ronin 
                        Cannabis has an amazing selection of all the best cannabis strains and weed 
                        accessories you'll be able to find in Guelph!
                `]}
                buttontext={"shop now"}
                bannerImage={data.bannerImage}
                location={'guelph'}
            />
            <BackgroundImage
                Tag="div"
                {...bgImageProd}
                preserveStackingContext
                style={{
                    width: '100%',
                    minHeight: '100vh'
                }}
            >
                <StyledContainer breakpoint="1200px">
                    <CategorySection
                        data={categoriesDataA}
                        columns='3'
                        subtitle="All the Cannabis Products You Could Ever Want"
                        title='The best weed products around'
                    />
                </StyledContainer>
            </BackgroundImage>
            <Review reviewdata={data.reviews}/>
            <LocationsMapSection
                title={"The #1 Cannabis Store in Guelph"}
                description={[
                    `Gone are the days of buying cannabis products from someone in 
                    an empty parking lot behind a building. Now there is a veritable 
                    sea of retail stores popping up all over Ontario.`,

                    `With so many options, how can you know where to buy the best 
                    weed in Guelph? Ronin Cannabis is here to help answer that 
                    question and provide amazing quality products to service all of 
                    your cannabis needs, no matter what you might be looking for.`,
                    
                    `Visit our Guelph retail store at 86 Gordon Street, and let us show 
                    you how we can help you take your cannabis experiences to the 
                    next level!`,
                ]}
                coordinates={cities.Guelph}
            />
            <ContactUs 
                hourstext={["10AM - 10PM MONDAY - SATURDAY","11AM - 8PM Sunday"]} 
                address={"86 Gordon Street, Guelph, ON"}
                email={"info@ronincannabis.ca"} 
                phone={"(519)-714-0420"}
            />
            <WhatSetsUsApart 
                maintitle={"What Sets Ronin Cannabis Apart?"}
                titleA={"Knowledge & Expertise"}
                contentA={`We take great pride in training
                our staff of expert budtenders in everything 
                that we can about each and every product we carry. 
                This way, when you have questions, they've always 
                got answers.`}
                titleB={"We Have New, Fresh Product Weekly"}
                contentB={`You'll have a hard time finding 
                a better selection than what we can offer. 
                Between our amazing array of weed accessories, 
                top-quality cannabis buds and our delicious edibles 
                and cannabis drinks, what more could you ask for?`}
                titleC={"Conscious Consumption"}
                contentC={`With only legal, regulated products for sale, 
                you can rest assured that every product you can purchase 
                from Ronin Cannabis underwent a rigorous quality control 
                process, meant to keep the products top-quality, and to 
                keep you safe.`}
            />
            <FaqSection faqdata={data.faq}/>
        </>
    )
}

export const query = graphql`
    query{
        faq: allSanityFaq(filter: {page: {eq: "Guelph Page"}}) {
            nodes {
                answer
                location
                question
            }
        }

        reviews: allSanityReview(filter: {location: {eq: "Guelph"}}) {
            nodes {
                location
                review
                stars
                title
                customer
            }
        }

        bannerImage: file(relativePath: { eq: "guelph-bg.jpg" }) {
            childImageSharp {
                gatsbyImageData(
                    width: 1900
                    quality:90
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                )
            }
        }


        productbg: file(relativePath: { eq: "productbg1.jpg" }) {
            childImageSharp {
                gatsbyImageData(
                    width: 1900
                    quality:90
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                )
            }
        }
    }
`;